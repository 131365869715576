import { filterNews } from '~/stores/news/FiltersNewsStore';

/**
 * clean store Filters   News  if routing not matched news
 */
export default defineNuxtRouteMiddleware((to, from) => {
    const availableFrom = from.path.includes('news');
    const availableTo = to.path.includes('news');
    const filterNewsStore = filterNews();
    if (!availableFrom || !availableTo) {
        filterNewsStore.$reset();
    }
});
